import { PencilSquare, QrCodeScan, SignTurnLeft } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { Button, ButtonGroup } from '../Components/Buttons';

export default function Tutorial({next}) {
    const { t, i18n } = useTranslation();

    return(
        <React.Fragment>
            <p className='text-center text-4xl mb-12 font-bold'>{t("tutorial_title")}</p>
            <div className='flex flex-col gap-6 mb-12 items-center text-xl font-medium [&>div]:text-center [&>div]:flex [&>div]:gap-4 [&>div]:items-center'>
                <div>
                    <QrCodeScan/>
                    <p>{t("tutorial_scan")}</p>
                </div>
                <div>
                    <PencilSquare/>
                    <p>{t("tutorial_solve")}</p>
                </div>
                <div>
                    <SignTurnLeft/>
                    <p>{t("tutorial_next")}</p>
                </div>
            </div>
            <ButtonGroup width={"50%"}>
                <Button event={next}>{t("start_game")}</Button>
            </ButtonGroup>
        </React.Fragment>
    )
}