import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { config } from "../data.ts";

export function Authors({}) {
    const { t, i18n } = useTranslation();
    let lng = i18n.language;

    return(
        <React.Fragment>
            <div className="text-center">
            {config.info.authors.map((el, i) => 
                <div className="mb-2" key={"authors_"+i}>
                    <p className="font-bold text-xl">{el.title[lng]}</p>
                    <p className="text-lg">{el.name}</p>
                </div>
            )}
            </div>
            <p className="text-center mt-8"><span>{t('version')}</span>: <span className="font-bold">{config.info.version}</span></p>
            <p className="text-center mt-2 font-bold">© Adam Hojer 2023</p>
            <p className="text-center mt-2 font-bold max-w-[200px] m-auto">{t('created_by')}</p>
        </React.Fragment>
    )
}