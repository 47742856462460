import { globalContent, globalMap, globalScene, globalScore } from "./globals";
import { config, data, maps } from "./data.ts";
import "./style.css"
import Menu from "./Modules/Menu";
import Tutorial from "./Modules/Tutorial";
import { Question } from "./Modules/Question";
import { Authors } from "./Modules/Authors";
import { Task } from "./Modules/Task";
import { List } from "react-bootstrap-icons";
import { useEffect, useRef, useState } from "react";
import Cookies from "js-cookie";
import { End } from "./Modules/End";
import { Letter } from "./Modules/Letter";
import { Qr } from "./Modules/Qr";
import { Story } from "./Modules/Story";
import ekoMenu from "./Modules/EKOmenu.js";
//import { SaveSystem } from "./SaveSystem.js";
import EkoEnd from "./Modules/EKOend.js";
import Pexeso from "./Modules/Pexeso.js";
import Fields from "./Fields.js";

function App() {
  const [content, setContent] = globalContent.useState();
  const [score, setScore] = globalScore.useState();
  const [scene, setScene] = globalScene.useState();
  const [gameMap, setGameMap] = globalMap.useState();

  var map = maps[gameMap];

  useEffect(() => {
    map = maps[gameMap];
  }, [gameMap]);

//  const save = new SaveSystem;
  
  const [initRender, setInitRender] = useState(true);

  useEffect(() => {
    if(initRender) {
      if(config.info.initScreen != undefined && config.info.initScreen !== scene) {
        setScene(config.info.initScreen);
      }
      return;
    }
    Cookies.set('save', content);
  }, [content])

  useEffect(() => {
    if(initRender) {
      return;
    }
    Cookies.set('score', score)
  }, [score])

  useEffect(() => {
    if(Cookies.get('score') != undefined) {
      setScore(parseInt(Cookies.get('score')));
    }
    if(Cookies.get('save') != undefined) {
      setContent(parseInt(Cookies.get('save')));
    }
    setInitRender(false);
  }, [])

  const modules = {
    menu: Menu,
    tutorial: Tutorial,
    question: Question,
    authors: Authors,
    task: Task,
    end: End,
    letter: Letter,
    qr: Qr,
    story: Story,
    ekoMenu: ekoMenu,
    ekoEnd: EkoEnd,
    pexeso: Pexeso,
    fields: Fields
  }

  function new_game(id) {
    if(window.confirm('Skutečně zahájit novou hru? Přijdete o předchozí save!')) {
      setGameMap(id);
      setScore(0);
      setContent(maps[id][0]);
      setScene(data[maps[id][0]].type);
    }
  }

  function continue_game() {
    setScene(data[content].type);
  }

  function next() {
    if(data[map[map.indexOf(content)+1]].noQrCode != undefined && (data[map[map.indexOf(content)+1]].noQrCode)) {
      setContent(map[map.indexOf(content)+1]);
      setScene(data[map[map.indexOf(content)+1]].type);
    }
    else {
      setScene('qr');
    }
  }

  let ActualModule = modules[scene];

  return (
    <div className="App" style={{color: config.colors.default_color}}>
      <div id="ui" className="absolute top-0 left-0 w-full h-full">
        <div id="bg" className="z-0 h-full">
          <div className="w-full h-full bg-no-repeat bg-cover bg-center" style={{backgroundImage: config.colors.default_bg_img}}/>
          <div id="overlay" className="w-full h-full absolute top-0 left-0" style={{backgroundColor: config.colors.overlay_color}}/>
        </div>
        <div id="top_menu" className="absolute top-0 left-0 justify-between flex w-full p-2 z-40">
          <div>
            <p className="text-xl">{score+" b."}</p>
          </div>
          <div className="cursor-pointer" onClick={() => setScene(config.info.menuScreen)}>
            <List size={36}/>
          </div>
        </div>
      </div>
      <div id="content" className="absolute top-0 left-0 z-20 pt-16 px-4 w-full h-screen overflow-y-auto">
        <ActualModule data={data[content] != undefined?data[content].res:null} next={next} menu_btns={{new: new_game, continue: continue_game, authors: () => setScene('authors')}}/>
      </div>
    </div>
  );
}

export default App;