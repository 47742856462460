import { Button, ButtonGroup } from "../Components/Buttons";
import React from "react";
import { useTranslation } from 'react-i18next';
import { globalScore } from "../globals";
import { games } from "../data.ts";

export default function EkoEnd({menu_btns}) {
    const [score, setScore] = globalScore.useState();
    const { t, i18n } = useTranslation();

    function review() {
        let res = games[1].rating.filter((e) => e.from == score);
        if(res.length > 0) {
            return res[0].text;
        }
        else {
            return "Super! Děkujeme za hraní! Budeme rádi, když se příští rok potkáme na INFISu";
        }
    }

    return(
        <React.Fragment>
            <h1 className="text-center text-4xl mb-8 font-bold">Získal/a jsi</h1>
            <p className="text-4xl font-extrabold text-center">{score+"/5"}</p>
            <p className="text-3xl text-center font-bold">bodů</p>
            <p className="text-center my-2 text-xl">{t('eco_new_title')}</p>
            <p className="text-2xl text-center px-4 my-4 font-bold">{`${review()}`}</p>
            <ButtonGroup width={"60%"}>
                <Button event={menu_btns.new}>{t('new_game')}</Button>
                <Button event={menu_btns.authors}>{t('authors')}</Button>
            </ButtonGroup>
        </React.Fragment>
    )
}