import React, { useEffect, useState } from "react";
import { data, maps } from "../data.ts";
import { Input, InputGroup } from "../Components/Inputs";
import { Button, ButtonGroup } from "../Components/Buttons";
import { globalContent, globalMap, globalScene } from "../globals";
import { QrScanner } from "@yudiel/react-qr-scanner";
import { useTranslation } from "react-i18next";

export function Qr() {
    const [content, setContent] = globalContent.useState();
    const [scene, setScene] = globalScene.useState();

    const [gameMap, setGameMap] = globalMap.useState();

    var map = maps[gameMap];
  
    useEffect(() => {
      map = maps[gameMap];
    }, [gameMap]);

    const { t, i18n } = useTranslation();

    const [codeInput, setCodeInput] = useState(null);

    function check_code(code) {
        if(code == undefined) {
            code = parseInt(codeInput);
        }
        else {
            const url = new URL(code);
            code = url.searchParams.get('id');
        }
        console.log(code);
        console.log(map[map.indexOf(content)+1]);
        if(map[map.indexOf(content)+1] === parseInt(code)) {
            setContent(map[map.indexOf(content)+1]);
            setScene(data[map[map.indexOf(content)+1]].type);
        }
        else {
            alert("Tenhle kód neznáme ¯\_(ツ)_/¯")
        }
    }

    return(
        <React.Fragment>
            <p className="text-3xl text-center mb-4">{t('qr_scan')}</p>
            <QrScanner
                tracker={false}
                onDecode={(res) => check_code(res)}
                onError={(err) => console.log('Problem with QR scanner, err: '+err)}
            />
            <p className="text-2xl text-center my-4">{t('qr_code')}</p>
            <InputGroup width={"55%"}>
                <Input value={codeInput} onChange={(num) => setCodeInput(num)} type="number" placeholder={t('qr_label_code')}></Input>
            </InputGroup>
            <ButtonGroup width={"45%"}>
                <Button event={() => check_code()}>{t('qr_check')}</Button>
            </ButtonGroup>
        </React.Fragment>
    )
}