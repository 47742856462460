import { config } from "../data.ts";

export function Input({type, placeholder, onChange, value}) {
    return(
        <input value={value!=undefined?value:undefined} onChange={(e) => onChange(e.target.value)} type={type} placeholder={placeholder} className="p-4 text-center border-2 w-full border-border-default bg-box-default" style={{borderWidth: config.border.size, borderRadius: config.border.radius}}>

        </input>
    )
}

export function InputGroup({children, width}) {
    return(
        <div className="flex flex-col items-stretch gap-2 p-2 m-auto" style={{width: width}}>
            {children}
        </div>
    )
}