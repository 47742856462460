import React, { useEffect } from "react"
import { useTranslation } from 'react-i18next';
import { Button, ButtonGroup } from "../Components/Buttons";
import { globalScore } from "../globals";
import { useState } from "react";

export function Question({data, next}) {
    const { t, i18n } = useTranslation();
    let lng = i18n.language;

    const [score, setScore] = globalScore.useState();
    const [answered, setAnswered] = useState(false);

    const [answers, updateAnswers] = useState([]);

    const shuffle = (array) => { 
        for (let i = array.length - 1; i > 0; i--) { 
          const j = Math.floor(Math.random() * (i + 1)); 
          [array[i], array[j]] = [array[j], array[i]]; 
        } 
        return array; 
    };

    useEffect(() => {
        console.log(data[lng].answers);
        updateAnswers([]);
        if(data.shuffle) {
            let right = data[lng].answers[data.right];
            console.log(right);
            data[lng].answers = shuffle(data[lng].answers);
            console.log(data[lng].answers);
            data.right = data[lng].answers.indexOf(right);
            console.log(data.right);
        }
    }, [data]);

    function checkAnsw(answ) {
        answers.push(answ);
        if(answ == data.right) {
            setTimeout(() => {next()}, 3000);
            setAnswered(true);
            setScore(score+500);
        }
        else {
            setScore(score-300);
        }
    }

    return(
        <React.Fragment>
            <p className="text-3xl text-center mb-8">{data[lng].text}</p>
            <ButtonGroup width={"80%"}>
                {data[lng].answers.map((el, i) => <Button disabled={answers.includes(data.right) || answered} animation={answers.includes(i)?(data.right == i?"right_answ 2s forwards":"wrong_answ 2s forwards"):null} key={"question_"+i} event={() => checkAnsw(i)}>{el}</Button>)}
            </ButtonGroup>
        </React.Fragment>
    )
}