import React from "react";
import { Button, ButtonGroup } from "../Components/Buttons";

export function Story({data, next}) {
    return(
        <React.Fragment>
            <p className="text-2xl text-center mb-2">{data.text}</p>
            <ButtonGroup width={"60%"}>
                <Button event={next}>Pokračovat</Button>
            </ButtonGroup>
        </React.Fragment>
    )
}