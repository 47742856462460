import React from "react";
import { useTranslation } from "react-i18next";
import { Button, ButtonGroup } from "../Components/Buttons";

export function Letter({data, next}) {
    const { t, i18n } = useTranslation();
    let lng = i18n.language;

    return(
        <React.Fragment>
            <p className="text-3xl mb-4 text-center">{data[lng].title}</p>
            <img src={data.src} className="w-full p-4"/>
            <ButtonGroup width={"60%"}>
                <Button event={next}>Na další stanoviště!</Button>
            </ButtonGroup>
        </React.Fragment>
    )
}