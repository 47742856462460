import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Select from "./Components/Select";
import { ButtonGroup, Button } from "./Components/Buttons";
import { globalScore } from "./globals";

export default function Fields({data, next}) {
    const { t, i18n } = useTranslation();
    let lng = i18n.language;
    const [score, setScore] = globalScore.useState();
    const [answs, setAnsws] = useState([]);
    var selectedAnsws = [];
    const [checked, setChecked] = useState(false);
    const [checkedAllTrue, setAllTrue] = useState(false);

    const shuffle_pairs = (array) => { 
        for (let i = array.length - 1; i > 0; i--) { 
          const j = Math.floor(Math.random() * (i + 1)); 
          [array[i], array[j]] = [array[j], array[i]]; 
        } 
        return array; 
    };

    useEffect(() => {
        let tmp = [];
        tmp = data[lng].pairs.map((el) => el.answer);
        for(let i = 0; i < data[lng].extraOptions.length; i++) {
            tmp.push(data[lng].extraOptions[i]);
        }
        shuffle_pairs(tmp);
        setAnsws(tmp);
    }, []);

    useEffect(() => {
        console.log(answs);
    }, [answs])

    /*
    useEffect(() => {
        setChecked(false);
    }, [selectedAnsws]);
    */

    function check() {
        let right = 0;
        /*for(let x = 0; x < data[lng].pairs.length; x++) {
            if(selectedAnsws[x] == data[lng].pairs[x].answer) {
                right++;
            }
        }*/
        let prvky = document.querySelectorAll(".radek > select");
        for(let x = 0; x < prvky.length; x++) {
            if(prvky[x].value == prvky[x].getAttribute('data-answ')) {
                right++;
                prvky[x].style.background = "rgba(38, 209, 122, 0.8)";
            }
            else {
                prvky[x].style.background = "rgba(209, 38, 38, 0.8)";
            }
        }
        console.log(selectedAnsws);
        console.log(data[lng].pairs);
        console.log(right);
        if(right == data[lng].pairs.length) {
            setScore(score+1500);
            setAllTrue(true);
            setTimeout(() => {
                next();
            }, 1500);
        }
        else {
            setScore(score-400);
        }
        setTimeout(() => {setChecked(true)}, 50);
    }

    return(
        <React.Fragment>
            <div className="flex flex-col gap-4">
            {data[lng].pairs.map((input, i) => 
                <div className="radek w-full flex gap-4 justify-center items-center">
                    {input.img!=undefined?<img className="w-2/5 p-8" src={input.img}/>:<p className="text-xl text-center">{input.text}</p>}
                    <Select dataAnsw={input.answer} className={checked?(input.answer==selectedAnsws[i]?"bg-green-400":"bg-red-400"):null} event={(e) => {selectedAnsws[i] = e.target.value;}}>
                        {answs.map((el) =>
                            <option value={el}>{el}</option>
                        )}
                    </Select>
                </div>
            )}
            </div>
            <ButtonGroup width={"50%"}>
                <Button disabled={checkedAllTrue} event={() => check()}>Zkontrolovat</Button>
            </ButtonGroup>
        </React.Fragment>
    )
}