import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Lightbulb } from "react-bootstrap-icons";
import { Input, InputGroup } from "../Components/Inputs";
import { Button, ButtonGroup } from "../Components/Buttons";
import { globalScore } from "../globals";

export function Task({data, next}) {
    const { t, i18n } = useTranslation();
    let lng = i18n.language;
    const [score, setScore] = globalScore.useState();
    const [input, setInput] = useState(null);
    const [hint, setHint] = useState(false);
    const [checked, setChecked] = useState(false);

    function buyHint() {
        setScore(score-200);
        setHint(true);
    }

    function buyReveal() {
        setScore(score-500);
        setInput(data[lng].answers[0]);
        setChecked(true);
    }

    function checkAnsw() {
        if(data[lng].answers.includes(input.toLowerCase())) {
            setChecked(true);
            setScore(score+500);
        }
        else {
            setScore(score-100);
        }
    }

    return(
        <React.Fragment>
            <p className="text-3xl font-bold text-center">{data[lng].text}</p>
            {hint?<div className="text-2xl text-center flex gap-4 my-4 justify-center">
                <Lightbulb/>
                <p>{data[lng].hint}</p>
            </div>:null}
            <div>
                <InputGroup width={"75%"}>
                    <Input type="text" value={input} onChange={(e) => setInput(e)} placeholder="Odpověď"/>
                </InputGroup>
                <ButtonGroup width={"50%"}>
                    <Button disabled={hint || checked} hidden={hint || checked} event={() => buyHint()}>Nápověda (200 b.)</Button>
                    <Button disabled={checked} hidden={checked} event={() => buyReveal()}>Odhalit (500 b.)</Button>
                    <Button disabled={checked} hidden={checked} event={() => checkAnsw()}>Zkontrolovat</Button>
                    <Button disabled={!checked} hidden={!checked} event={next}>Pokračovat</Button>
                </ButtonGroup>
            </div>
        </React.Fragment>
    )
}