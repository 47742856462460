import { config } from "../data.ts";
import React from "react";

export function Button({children, event, disabled, hidden, animation}) {
    return(
        <React.Fragment>
            {!hidden || hidden== undefined?<button onClick={event} disabled={disabled} className="p-4 text-center border-2 w-full border-border-default bg-box-default" style={{borderWidth: config.border.size, borderRadius: config.border.radius, animation: animation}}>{children}</button>:null}
        </React.Fragment>
    )
}

export function ButtonGroup({children, width}) {
    return(
        <div className="flex flex-col items-stretch gap-4 p-2 m-auto" style={{width: width}}>
            {children}
        </div>
    )
}