const resources = {
    cs: {
        translation: {
            "escape_game": "Úniková hra",
            "continue": "Pokračovat",
            "new_game": "Nová hra",
            "authors": "Autoři",
            "settings": "Nastavení",
            "tutorial_title": "Jak hrát?",
            "tutorial_scan": "Naskenujte QR kód stanoviště",
            "tutorial_solve": "Vyřešte všechny úlohy",
            "tutorial_next": "Dle indicií vyražte vstříc dalšímu stanovišti!",
            "start_game": "Do hry!",
            "version": "Verze",
            "created_by": "Vytvořil organizační tým únikových her INFIS",
            "thanks": "Děkujeme za hraní!",
            "please_review": "Budeme moc rádi za zhodnocení.",
            "qr_scan": "Naskenujte QR kód dalšího stanoviště",
            "qr_code": "... nebo zadejte kód",
            "qr_label_code": "Kód stanoviště",
            "qr_check": "Ověřit",
            "eco_title": "Ekonomika na INFISu",
            "eco_undertitle": "Vyzkoušejte si projít kvízem na otázky ze světa ekonomiky!",
            "eco_new_title": "",
            "eco_logistic": "Logistika",
            "eco_finance": "Finančnictví",
            "eco_choose": "Vyber jedno z témat",
            "pexeso_tutorial": "Najděte správné dvojice",
            "pexeso_trials": "Tahů"
        }
    },
    en: {
        translation: {
            "escape_game": "Escape game",
            "continue": "Continue",
            "new_game": "New game",
            "authors": "Authors",
            "settings": "Settings",
            "tutorial_title": "How to play?",
            "tutorial_scan": "Scan QR code of place",
            "tutorial_solve": "Solve all activities on place",
            "tutorial_next": "And go right to the next place!",
            "start_game": "Start game!",
            "version": "Version",
            "created_by": "This game was created by team from INFIS.",
            "thanks": "Thank you for playing!",
            "please_review": "We'll be happy from your review about this game.",
            "qr_scan": "Scan QR code of the next place",
            "qr_code": "... or input the code",
            "qr_label_code": "Code of the place",
            "qr_check": "Check"
        }
    }
}

export default resources;