import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, ButtonGroup } from "../Components/Buttons";
import { Star, StarFill } from "react-bootstrap-icons";
import { config } from "../data.ts";
//import axios from "axios";
import { globalScore } from "../globals";
import Cookies from "js-cookie";

export function End({}) {
    const { t, i18n } = useTranslation();
    const [score, setScore] = globalScore.useState();
    const lng = i18n.language;
    const [sent, setSent] = useState(false);

    let FinalRating = [1, 1, 1, 1]

    function StarRow({el}) {
        const [rating, setRating] = useState(1);

        useEffect(() => {
            FinalRating[el.index-1] = rating;
        }, [rating])

        function StarElement({i}) {
            if(rating >= i) {
                return(<StarFill onClick={() => setRating(i)} size={24} color="gold"/>)
            }
            else {
                return(<Star onClick={() => setRating(i)} size={24}/>)
            }
        }

        return(
            <div className="flex flex-col items-center gap-2 mb-4">
                <p className="text-xl text-center">{el[lng]}</p>
                <div className="flex gap-2">
                    <StarElement i={1}/>
                    <StarElement i={2}/>
                    <StarElement i={3}/>
                    <StarElement i={4}/>
                    <StarElement i={5}/>
                </div>
            </div>
        )
    }


    function sendReview() {
        setSent(true);
        // axios.post(
        //     config.info.review.endpointUrl+(config.info.review.config.map((el, i) => (i==0?"?"+el.urlAtr+"="+FinalRating[i]:"&"+el.urlAtr+"="+FinalRating[i])).join(''))+"&score="+score+"&time="+Date.now()
        // )
        // .then((res) => {
        //     if(res.data == "OK") {
        //         alert("V pořádku odesláno ;)")
        //         alert("Díky za hodnocení, nyní bude stránka obnovena a save vymazán. Díky za hraní!");
        //         Cookies.remove('score');
        //         Cookies.remove('save');
        //         window.location.reload();
        //     }
        // })
        // .catch((err) => {
        //     setSent(false);
        //     alert('Bohužel se nepodařilo odeslat hodnocení. Zkuste to znovu a v případě přetrvávajících problémů konktaktujte organizátory hry. Děkujeme.');
        //     console.log(err);
        // })
    }

    return(
        <React.Fragment>
            <p className="text-2xl text-center">{t('thanks')}</p>
            {config.info.review.allow?<React.Fragment>
            <p className="text-center mb-6">{t('please_review')}</p>
            {config.info.review.config.map(el => 
            <StarRow key={el.urlAtr} el={el}/>)}
            <ButtonGroup width={"60%"}>
                <Button disabled={sent} event={() => sendReview()}>Hodnotit</Button>
            </ButtonGroup>
            </React.Fragment>:null}
        </React.Fragment>
    )
}