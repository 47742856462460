import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { withTranslation } from 'react-i18next';
import { Button, ButtonGroup } from "../Components/Buttons";
import { globalScore } from "../globals";

class Pexeso extends React.Component {
    shuffle_pairs = (array) => { 
        for (let i = array.length - 1; i > 0; i--) { 
          const j = Math.floor(Math.random() * (i + 1)); 
          [array[i], array[j]] = [array[j], array[i]]; 
        } 
        return array; 
    };

    constructor(props) {
        super(props);
        this.data = this.props.data;
        this.state = {
            flippedPieces: [],
            guessedPairs: [],
            disabled: false,
            tries: 0,
        }
        this.shuffle_pairs(this.data.pairs);
        this.t = this.props.t;
    }

    next = () => {
        /*const [score, setScore] = globalScore.useState();
        setScore(score+=(5000-(this.tries*200)));*/
        this.props.next();
    }

    pieceIsFlipped(type, object) {
        console.log(this.state);
        this.setState({
            tries: this.state.tries + 1
        })
        if(this.state.flippedPieces.length == 1) {
            if(this.state.flippedPieces[0].pairId == object.pairId) {
                console.log("Stejne");
                this.setState({
                    guessedPairs: this.state.guessedPairs.concat(object.pairId),
                    flippedPieces: []
                })
            }
            else {
                console.log("Odlisne");
                this.setState({disabled:true});
                setTimeout(() => {
                    this.state.flippedPieces[0].setState({flipped: false});
                    object.setState({flipped: false});
                    this.setState({
                        disabled: false,
                        flippedPieces: []
                    })
                }, 1500);
            }
        }
        else {
            if(type == "flipped") {
                this.setState({
                    flippedPieces: this.state.flippedPieces.concat(object)
                })
            }
            else if(type == "guessed") {
                this.setState({
                    guessedPairs: this.state.guessedPairs.concat(object)
                })
            }
        }
    }

    render() {
        return(
            <React.Fragment>
                <p className="text-center text-xl mb-2">{this.t('pexeso_tutorial')}</p>
                <p className="text-center text-2xl mb-4">{this.t('pexeso_trials')+": "+this.state.tries}</p>
                <div className="flex flex-wrap gap-4 justify-center">
                    {this.data.pairs.map((el) => 
                        <PexesoPiece id={el.pair} disabled={this.state.disabled} guessedPairs={this.state.guessedPairs} flippedPieces={this.state.flippedPieces} pieceIsFlipped={this.pieceIsFlipped.bind(this)}>
                            {el.img!==undefined?
                                <img className="max-w-full max-h-full" src={el.img}/>
                                :
                                <p className="break-all text-center text-xl">{el.text}</p>
                            }
                        </PexesoPiece>
                    )}
                </div>
                {
                    (this.state.guessedPairs.length == this.data.pairs.length/2)?
                    <ButtonGroup>
                        <Button event={() => this.next()}>Pokračovat</Button>
                    </ButtonGroup>:
                    null
                }
            </React.Fragment>
        )
    }
}

export default withTranslation()(Pexeso);

class PexesoPiece extends React.Component {
    constructor(props) {
        super(props);
        this.pairId = this.props.id;
        this.state = {
            flipped: false,
            guessed: false
        }
    }

    flip() {
        if(!this.state.flipped && !this.props.disabled) {
            this.setState({
                flipped: true
            });
            this.props.pieceIsFlipped("flipped", this);
        }
    }
    
    render() {
        return(
            <div onClick={() => this.flip()} className={"cursor-pointer rounded-2xl w-32 h-32 border-2 border-white flex justify-center items-center text-3xl font-bold bg-blue-400/50 "+(this.state.flipped?"p-4":null)}>
                {(!this.state.flipped)?<img className="rounded-xl" src="logo.png"/>:this.props.children}
            </div> 
        )
    }
}