import { Button, ButtonGroup } from "../Components/Buttons";
import React from "react";
import { useTranslation } from 'react-i18next';
import { globalScene } from "../globals";

export default function Menu({menu_btns}) {
    const [scene, setScene] = globalScene.useState();
    const { t, i18n } = useTranslation();

    return(
        <React.Fragment>
            <h1 className="text-center text-4xl mb-8 font-bold">{t('eco_title')}</h1>
            <p className="text-center my-2 text-xl">{t('eco_undertitle')}</p>
            <ButtonGroup width={"60%"}>
                <Button event={menu_btns.new}>{t('new_game')}</Button>
                <Button event={menu_btns.continue}>{t('continue')}</Button>
                <Button event={menu_btns.authors}>{t('authors')}</Button>
            </ButtonGroup>
        </React.Fragment>
    )
}